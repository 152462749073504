import React from 'react';
import { useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';

import API from '../Data/API';
import Error from '../Components/Error';
import UserContext from '../Contexts/UserContext';

import './Login.scss';

function Login(props) {
    const cookies = new Cookies();
    const { setUserData } = useContext(UserContext);
    const nav = useNavigate();

    const [formData, setFormData] = useState({
        errors: props.errors,
        loading: false,
        username: props.flashdata?.username || '',
        password: props.flashdata?.password || '',
    });

    const handleSubmit = (e: React.FormEvent<EventTarget>) => {
        e.preventDefault();

        setFormData({
            ...formData, ...{
                loading: true,
                errors: {}
            }
        });

        API.login(formData.username, formData.password).then((data) => {
            if (data.token) {
                // success
                cookies.set('token', data.token, { sameSite: 'strict' });

                setUserData({
                    type: "USER_LOGIN",
                    data: {
                        token: data.token, ...data.user
                    }
                })

                return nav('/me');
            }

            setFormData({
                ...formData, ...{
                    loading: false,
                    errors: data.errors || {}
                }
            });
        }).catch((e) => {
            setFormData({
                ...formData, ...{
                    loading: false,
                    errors: {
                        server: e.message
                    }
                }
            });
        });
    }

    return <main>
        <form className="Login" method="POST" onSubmit={handleSubmit}>
            <input type="text"
                placeholder="username"
                name="username"
                value={formData.username}
                onChange={e => setFormData({...formData, ...{username: e.target.value}})}
                disabled={formData.loading} />

            <Error error={formData.errors?.username} />

            <input type="password"
                placeholder="password"
                name="password"
                value={formData.password}
                onChange={e => setFormData({...formData, ...{password: e.target.value}})}
                disabled={formData.loading} />

            <Error error={formData.errors?.password} />

            <Error error={formData.errors?.server} />

            <button type="submit"
                disabled={formData.loading}>Login</button>
        </form>
    </main>;
}

export default Login;