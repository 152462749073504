import { createContext } from "react";

interface UserContextInterface {
  userData: {
    loading: boolean;
    user: User;
  };
  setUserData: (data: object) => void;
}

const UserContext = createContext<UserContextInterface>({
  userData: {
    loading: false,
    user: {
      id: '',
      username: ''
    },
  },
  setUserData: () => {},
});

export default UserContext;
