import * as React from "react";
import { Link } from "react-router-dom";
import './Header.scss';

import UserContext from '../Contexts/UserContext';

function Header() {
    const { userData } = React.useContext(UserContext);

    return <header>
        <h1><Link to="/">App</Link></h1>
        <nav>
            {!userData.user &&
                <>
                    <Link to="/login">Login</Link>
                    <Link to="/register">Register</Link>
                </>
            }

            {userData.user &&
                <>
                    <Link to="/me">{userData.user.username}</Link>
                    <Link to="/logout">Logout</Link>
                </>
            }
        </nav>
    </header>
}

export default Header;