import * as React from "react";
import UserContext from '../Contexts/UserContext';

function Me() {
    const { userData } = React.useContext(UserContext);

    return <main>
        <h1>Me</h1>
        <h2>ID: {userData.user.id}</h2>
        <h2>Username: {userData.user.username}</h2>
    </main>;
}

export default Me;