import * as React from "react";
import Counter from "../Components/Counter";

function Index() {
    return <main>
        <h2>Index</h2>
        <Counter />
    </main>;
}

export default Index;