import React from 'react';

interface ErrorProps{
    error: string;
}

function Error(props: ErrorProps) {
    if (props.error) {
        return <div className="error">{props.error}</div>;
    }
}

export default Error;