interface UserReducerAction {
  type: string;
  data: any;
}

function UserReducer(state, action: UserReducerAction) {
  switch (action.type) {
    case "USER_AUTH":
      localStorage.setItem("user", JSON.stringify(action.data));
      return { ...state, loading: false, user: action.data };

    case "USER_LOGIN":
      localStorage.setItem("user", JSON.stringify(action.data));
      return { ...state, user: action.data };

    case "USER_LOGOUT":
      localStorage.setItem("user", "false");
      return { loading: false, user: false };

    default:
      return state;
  }
}

export default UserReducer;
