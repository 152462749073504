import * as React from 'react';
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';

import API from '../Data/API';
import Error from '../Components/Error';
import UserContext from '../Contexts/UserContext';

import './Login.scss';

interface errorsInterface {
    password?: string,
    server?: string,
    username?: string,
}

interface flashdataProps {
    password?: string,
    username?: string,
}

interface RegisterProps {
    errors: errorsInterface,
    flashdata: flashdataProps,
};

function Register(props: RegisterProps) {
    const cookies = new Cookies();
    const { setUserData } = React.useContext(UserContext);
    const nav = useNavigate();

    const [formData, setFormData] = React.useState({
        errors: props.errors,
        loading: false
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        setFormData({
            loading: true,
            errors: {}
        });

        API.register(username, password).then((data) => {
            if (data.token) {
                // success
                cookies.set('token', data.token, { sameSite: 'strict' });

                setUserData({
                    type: "USER_LOGIN",
                    data: {
                        token: data.token, ...data.user
                    }
                })

                return nav('/me');
            }

            setFormData({
                loading: false,
                errors: data.errors || {}
            });
        }).catch((e) => {
            setFormData({
                loading: false,
                errors: {
                    server: e.message
                }
            });
        });
    }

    const [username, setUsername] = React.useState(props.flashdata?.username);
    const [password, setPassword] = React.useState(props.flashdata?.password);

    return <main>
        <form className="Register" method="POST" onSubmit={handleSubmit}>
            <input
                type="text"
                placeholder="username"
                name="username"
                value={username}
                onChange={e => setUsername(e.target.value)}
                disabled={formData.loading} />

            <Error error={formData.errors?.username} />

            <input
                type="text"
                placeholder="password"
                name="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                disabled={formData.loading} />

            <Error error={formData.errors?.password} />

            <Error error={formData.errors?.server} />

            <button type="submit"
                disabled={formData.loading}>Register</button>
        </form>
    </main>
}

export default Register;