import Cookies from "universal-cookie";

class API_Class {
  token: string = "";

  constructor() {
    const cookies = new Cookies();
    this.token = cookies.get("token");
  }

  base(path = "/", method = "GET", data = {}) {
    const options: RequestInit = {
      headers: {
        Authorization: `Bearer ${this.token}`,
        "Content-Type": "application/json",
      },
      method: method,
    };

    if (method !== "GET") {
      options.body = JSON.stringify(data);
    }

    return fetch(`${process.env.REACT_APP_API_URL}${path}`, options);
  }

  async login(username: string, password: string) {
    const data = {
      username: username,
      password: password,
    };

    const response = await this.base("/login", "POST", data);
    return await response.json();
  }

  async logout() {
    const response = await this.base("/logout");
    return await response.json();
  }

  async me() {
    const response = await this.base("/me");
    return await response.json();
  }

  async register(username: string, password: string) {
    const data = {
      username: username,
      password: password,
    };

    const response = await this.base("/register", "POST", data);
    return await response.json();
  }
}

const API = new API_Class();

export default API;
