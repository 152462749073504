import * as React from 'react';
import { Navigate } from "react-router-dom";
import UserContext from '../Contexts/UserContext';

function RequireAuth(props: RequireAuthProps) {
    const { userData } = React.useContext(UserContext);

    if (userData.loading) {
        return <main>Auth Loading...</main>
    }

    return userData.user.id ? props.children : <Navigate to={props.redirectTo} />;
}

export default RequireAuth;