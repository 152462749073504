import { useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';

import API from '../Data/API';
import UserContext from '../Contexts/UserContext';

function Logout() {
    const cookies = new Cookies();
    const { setUserData } = useContext(UserContext);
    const nav = useNavigate();

    useEffect(() => {
        API.logout().then((data) => {
            cookies.remove('token');

            setUserData({
                type: "USER_LOGOUT",
            });

            nav('/');
        }).catch((e) => {
            console.log('logout error', e);
        });
    }, []);

    return <main>
        <h2>Logging out</h2>
    </main>
}

export default Logout;