import * as React from 'react';
import { Navigate } from "react-router-dom";
import UserContext from '../Contexts/UserContext';

function RequireNoAuth(props: RequireAuthProps) {
    const { userData } = React.useContext(UserContext);

    return userData.user ? <Navigate to={props.redirectTo} /> : props.children;
}

export default RequireNoAuth;