import * as React from "react";
import { Routes, Route } from "react-router-dom";

import API from './Data/API';
import RequireAuth from './Data/RequireAuth';
import RequireNoAuth from './Data/RequireNoAuth';

import Header from './Components/Header';

import Index from './Pages/Index';
import Login from './Pages/Login';
import Logout from './Pages/Logout';
import Me from './Pages/Me';
import NotFound from './Pages/NotFound';
import Protected from './Pages/Protected';
import Register from './Pages/Register';

//
import UserContext from './Contexts/UserContext';
import UserReducer from './Reducer/UserReducer';

function RoutesElement(props) {
    const userInitial = {
        loading: !props.request.user?.id,
        user: props.request.user
    };
    const [userData, setUserData] = React.useReducer(UserReducer, userInitial);
    
    React.useEffect(() => {
        API.me().then((data) => {
            setUserData({
                type: "USER_AUTH",
                data: data.user,
            });
        }).catch((e) => {
            console.log('me error', e);
        });
    }, []);

    return (
        <UserContext.Provider value={{ userData, setUserData }}>
            <Header />
            <Routes location={props.location}>
                <Route
                    path='/'
                    element={<Index />}
                />
                <Route
                    path='/login'
                    element={<RequireNoAuth redirectTo="/me">
                        <Login
                            errors={props.errors}
                            flashdata={props.flashdata}
                        />
                    </RequireNoAuth>} />
                <Route
                    path='/logout'
                    element={<Logout />} />
                <Route
                    path='/register'
                    element={<RequireNoAuth redirectTo="/me">
                        <Register
                            errors={props.errors}
                            flashdata={props.flashdata}
                        />
                    </RequireNoAuth>} />
                <Route
                    path='/protected'
                    element={<RequireAuth redirectTo="/login">
                        <Protected />
                    </RequireAuth>} />
                <Route
                    path='/me'
                    element={<RequireAuth redirectTo="/login">
                        <Me />
                    </RequireAuth>} />
                <Route
                    path='*'
                    element={<NotFound />} />
            </Routes>
        </UserContext.Provider>
    );
}

export default RoutesElement;