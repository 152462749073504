import React from "react";
import Routes from "./Routes";
import './App.scss';

interface AppProps {
  request: any,
  errors: {},
  flashdata: {},
}

function App(props: AppProps) {
  return (
    <div className="App">
      <Routes
        request={props.request}
        errors={props.errors}
        flashdata={props.flashdata} />
    </div>
  );
}

export default App;
